import React from "react";
import "./style.css"
import { Box, Text} from '@chakra-ui/react'
import { Heading } from '@chakra-ui/react'
import img1 from './plat1-min.png'
import img2 from './plat2-min.png'
import img3 from './plat3-min.png'
import img4 from './plat4-min.png'

function AndroidUI(){

    return(
        <Box paddingTop={"10vh"} paddingLeft="40px">

            <div class="secPlatfomrIO">
                <Heading margin="10px 0px" size='4xl' align="center" fontFamily="RoundLight">Instalar PlatformIO</Heading >
                <Text
                    className="textPlatformio secSubTitulo"
                            fontFamily="Round"
                            mt={1}
                            display='block'
                            fontSize='3xl'
                            lineHeight='normal'
                            fontWeight='semibold'
                            >
                            1.- Vamos a extensiones y buscamos PlatformIO
                            </Text>
                <div class="imgPonentePlatformIO">
                    <img src={img1} alt=""/>
                    <img src={img2} alt=""/>
                </div>
            </div>


            <div class="secPlatfomrIO">
                <Text
                    className="textPlatformio secSubTitulo"
                            fontFamily="Round"
                            mt={1}
                            display='block'
                            fontSize='3xl'
                            lineHeight='normal'
                            fontWeight='semibold'
                            >
                            2.- En la pantalla de PlatformIO le damos a instalar
                            </Text>
                <div class="imgPonentePlatformIO2">
                    <img src={img3} alt=""/>
                </div>
          
             </div>


             <div class="secPlatfomrIO">
                
                <Text
                    className="textPlatformio secSubTitulo"
                            fontFamily="Round"
                            mt={1}
                            display='block'
                            fontSize='3xl'
                            lineHeight='normal'
                            fontWeight='semibold'
                            >
                            3.- Listo, ya tendrás platformIO instalado !!!
                            </Text>
                <div class="imgPonentePlatformIO2">
                    <img src={img4} alt=""/>
                </div>
          
            </div>






        </Box>
    );
}

export default AndroidUI;