
import './test.css'
import './styles.css'
import { Link, Text, Box, Button } from '@chakra-ui/react'

const Test = () => { 

    

    return ( 
      
            <Box className='fondo'>

            </Box>
            


    ); 

}; 

 

export default Test; 