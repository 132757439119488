import React from "react";
import "./MQTT.css"
import { Box, useColorModeValue, Stack} from '@chakra-ui/react'
import { Code } from '@chakra-ui/react'
import { Center} from '@chakra-ui/react'
import {Link} from 'react-scroll'

function AndroidMQTT(){

    const colorBtn = useColorModeValue("#ff414d", "#ff414d");
    const sectionColorBG = useColorModeValue("#EFF5F6B8", "gray.800");

    return(
        <Box paddingTop={"10vh"}>
            <Box>
                <Box className="titulo"><p>Android MQTT</p></Box>
            </Box>
            <Stack direction={['column', 'column', 'row', 'row']} w={["100%","100%","100%","100%"]} h={["100%","100%","100%","100%"]}>
            <Stack className="menu" w={["100%","100%","20%","20%"]} h={["100%","100%","auto","auto"]}>
                <Stack position={["relative", "relative", "fixed", "fixed"]} direction={['row', 'row', 'column', 'column']}
                        h={["20%","20%","auto","auto"]} w={["100%","100%","20%","20%"]}>
                    <a>Secciones</a>
                    <Link className="Link" to="inicio" spy={true} smooth={true} > - Inicio</Link>
                    <Link className="Link" to="configuraciones" spy={true} smooth={true} > - Configuración</Link>
                    <Link className="Link" to="conexion" spy={true} smooth={true} > - Conexión al servidor</Link>
                    <Link className="Link" to="desconexion" spy={true} smooth={true} > - Desconexión</Link>
                    <Link className="Link" to="suscripcion" spy={true} smooth={true} > - Suscripción</Link>
                    <Link className="Link" to="cancelarSuscripcion" spy={true} smooth={true} > - Cancelar suscripción</Link>
                    <Link className="Link" to="callback" spy={true} smooth={true} > - Callback</Link>
                    <Link className="Link" to="publicacion" spy={true} smooth={true} > - Publicación</Link>
                </Stack>
            </Stack>
            <Box className="contenido">
                
                <Box className="subTitulo" id="inicio"><p>Para usar esta biblioteca</p></Box>
                <Box className="texto"><p>1.- Agregar Maven a Gradle Project</p></Box>
                    <Code colorScheme='telegram'>{"allprojects { "}
                            <span>&nbsp;</span> {"repositories {" } <br/>
                            <span>&nbsp;&nbsp;</span> {"..." }<br/>
                            <span>&nbsp;&nbsp;</span> {"maven { url 'https://jitpack.io'}"}<br/>
                            <span>&nbsp;</span> {"}"}<br/>
                        {"}"}
                    </Code>
                <Box className="texto">2.- Agrega la dependencia a Gradle APP</Box>
                    <Code colorScheme='telegram'>{"dependencies {"}<br/>
                            <span>&nbsp;</span>{"implementation 'androidx.legacy:legacy-support-v4:1.0.0'"}<br/>
                            <span>&nbsp;</span>{"implementation 'mx.electronica117:Android-MQTT:1.2'"}<br/>
                            {"}"}<br/>
                    </Code>
                <Box className="texto">3.- Agrega los permisos al Manifest</Box>
                    <Code colorScheme='telegram'>    
                        {'<uses-permission android:name="android.permission.WAKE_LOCK"/>'}<br/>
                        {'<uses-permission android:name="android.permission.INTERNET"/>'}<br/>
                        {'<uses-permission android:name="android.permission.ACCESS_NETWORK_STATE"/>'}                     
                    </Code>
                
                <Box>
                    <p className="texto">Ultima versión:<span>&nbsp;&nbsp;</span> 
                        <a href="https://jitpack.io/#mx.electronica117/Android-MQTT">
                        <img src="https://jitpack.io/v/mx.electronica117/Android-MQTT.svg" alt=""/>
                        </a>
                    </p>
                </Box>  







                <Box className="seccion">
                    <Box className="subTitulo" id="configuraciones"><p>Configuraciones</p></Box>
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","60%","60%"]}>
                            <Box className="texto"><p>Cambiar servidor MQTT</p></Box>
                            <Box className="textoContenido"><p>Por defecto tiene configurado un broker publico si deseas cambiarlo utiliza el método setServer(String), puedes hacerlo desde cualquier clase.
                                                        El valor por defecto es “tcp://broker.hivemq.com”</p></Box>
                        </Box>
                        <Box  w={["100%","100%","40%","40%"]} h="auto">
                            <Center w="100%" h="100%"><Code colorScheme='telegram'> {"MQTT.setServer(\"borker\");"} </Code></Center>
                        </Box>
                    </Stack>
                </Box>

                <Box className="seccion">
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","60%","60%"]}>
                            <Box className="texto"><p>Cambiar puerto</p></Box>
                            <Box className="textoContenido"><p>Para cambiar al puerto al que se conecta el dispositivo utiliza el método setPuerto(int) o setPuerto(String).
El valor por defecto es 1883</p></Box>
                        </Box>
                        <Box  w={["100%","100%","40%","40%"]} h="auto">
                            <Center w="100%" h="100%"><Code colorScheme='telegram'> {"MQTT.setPuerto(1234);"} <br/>
                            {"MQTT.setPuerto(\"1234\");"} </Code></Center>
                        </Box>
                    </Stack>
                </Box>

                <Box className="seccion">
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","60%","60%"]}>
                            <Box className="texto"><p>Cambiar calidad de servicio Qos</p></Box>
                            <Box className="textoContenido"><p>Para cambiar la calidad de servicio utiliza el método setQos(int), los valores admitidos son 0, 1, 2.
El valor por defecto es 2.</p></Box>
                        </Box>
                        <Box  w={["100%","100%","40%","40%"]} h="auto">
                            <Center w="100%" h="100%"><Code colorScheme='telegram'> {"MQTT.setQos(2);"}</Code></Center>
                        </Box>
                    </Stack>
                </Box>

                <Box className="seccion">
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","60%","60%"]}>
                            <Box className="texto"><p>Obtener valores actuales</p></Box>
                            <Box className="textoContenido"><p>Para obtener cada uno de los atributos anteriores utiliza los metodos siguientes getServer(), getPuerto(), getQos().</p></Box>
                        </Box>
                        <Box  w={["100%","100%","40%","40%"]} h="auto">
                            <Center w="100%" h="100%"><Code colorScheme='telegram'> {"String puerto = MQTT.getPuerto();"}
                            <br/>
                            {"String server = MQTT.getServer();"}
                            <br/>
                            {"int qos = MQTT.getQos();"}</Code></Center>
                        </Box>
                    </Stack>
                </Box>

                <Box className="seccion">
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","60%","60%"]}>
                            <Box className="texto"><p>Verificar conexión</p></Box>
                            <Box className="textoContenido"><p>Para saber si estas conectado al servidor utiliza el siguiente método.
isConnected()</p></Box>
                        </Box>
                        <Box  w={["100%","100%","40%","40%"]} h="auto">
                            <Center w="100%" h="100%"><Code colorScheme='telegram'> 
                                {"if(MQTT.isConnected()){"}<br/>
                                {"// Conectado"}<br/>
                                {"}else{"}<br/>
                                    {"// Desconectado"} <br/>
                                {"}"}
                            </Code></Center>
                        </Box>
                    </Stack>
                </Box>








                <Box className="seccion">
                    <Box className="subTitulo" id="conexion"><p>Conexión</p></Box>
                    <Box className="texto"><p>Para iniciar la conexión con el servidor hay dos métodos que se pueden usar</p></Box> 
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","50%","50%"]}>
                            <Box className="texto"><p>Conexión a servidor sin usuario</p></Box>
                            <Box className="textoContenido"><p>Utiliza ConnectMQTT(listener) para conectarte al servidor sin usar usuario y contraseña.</p></Box>
                        </Box>
                        <Box  w={["100%","100%","50%","50%"]} h="auto">
                            <Center w="100%" h="100%">
                                <Code colorScheme='telegram'>
                                {"MQTT.ConnectMQTT(new MQTTListener() {"}<br/>
                                {"@Override"}<br/>
                                {"public void onSuccess() {"}<br/>
                                {"// Operación exitosa"}<br/>
                                {"}"}<br/>
                                {"@Override"}<br/>
                                {"public void onFailure() {"}<br/>
                                {"// Fallo en la operación"}<br/>
                                {"}"}<br/>
                                {"});"}<br/>
                                </Code></Center>
                        </Box>
                    </Stack>
                </Box>
                <Box className="seccion">
                   <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","50%","50%"]}>
                            <Box className="texto"><p>Conexión a servidor con usuario</p></Box>
                            <Box className="textoContenido"><p>Utiliza ConnectMQTTWithUser(String, String, listener) para conectarte al servidor utilizando usuario y contraseña.</p></Box>
                        </Box>
                        <Box  w={["100%","100%","50%","50%"]} h="auto">
                            <Center w="100%" h="100%"><Code colorScheme='telegram'> 
                            {"MQTT.ConnectMQTTWithUser(\"User\", \"Pass\", new MQTTListener() {"}<br/>
                            {"@Override"}<br/>
                            {"public void onSuccess() {"}<br/>
                            {"// Operación exitosa"}<br/>
                            {"}"}<br/>
                            {"@Override"}<br/>
                            {"public void onFailure() {"}<br/>
                            {"// Fallo en la operación"}<br/>
                            {"}"}<br/>
                            {"});"}<br/>
                            </Code></Center>
                        </Box>
                    </Stack>
                </Box>






                <Box className="seccion">
                    <Box className="subTitulo" id="desconexion"><p>Desconexión</p></Box>
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","50%","50%"]}>
                            <Box className="texto"><p>Desconexión del servidor</p></Box>
                            <Box className="textoContenido"><p>Para desconectarte del servidor solo basta con utilizar el siguiente método.
                                                                Disconect(listener)</p></Box>
                        </Box>
                        <Box  w={["100%","100%","50%","50%"]} h="auto">
                            <Center w="100%" h="100%"><Code colorScheme='telegram'> 
                            {"MQTT.Disconect(new MQTTListener() {"}<br/>
                            {"@Override"}<br/>
                            {"public void onSuccess() {"}<br/>
                            {"// Operación exitosa"}<br/>
                            {"}"}<br/>
                            {"@Override"}<br/>
                            {"public void onFailure()"}<br/>
                            {"// Fallo en la operación"}<br/>
                            {"}"}<br/>
                            {"});"}
                            </Code></Center>
                        </Box>
                    </Stack>
                </Box>






                <Box className="seccion">
                    <Box className="subTitulo" id="suscripcion"><p>Suscripción</p></Box>
                    <Box className="texto"><p>Para suscribirte a uno o varios tópico solo tienes que usar alguno de lo siguientes métodos.</p></Box> 
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","40%","40%"]}>
                            <Box className="texto"><p>Suscripción a un tópico</p></Box>
                        </Box>
                        <Box  w={["100%","100%","60%","60%"]} h="auto">
                            <Center w="100%" h="100%">
                                <Code colorScheme='telegram'>
                                {"MQTT.Suscribe(\"Topico\", new MQTTListener() {"}<br/>
                                {"@Override"}<br/>
                                {"public void onSuccess() {"}<br/>
                                {"// Operación exitosa"}<br/>
                                {"}"}<br/>
                                {"@Override"}<br/>
                                {"public void onFailure() {"}<br/>
                                {"// Fallo en la operación"}<br/>
                                {"}"}<br/>
                                {"});"}
                                </Code></Center>
                        </Box>
                    </Stack>
                </Box>
                <Box className="seccion">
                   <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","40%","40%"]}>
                            <Box className="texto"><p>Suscripción a varios tópicos</p></Box>
                        </Box>
                        <Box  w={["100%","100%","60%","60%"]} h="auto">
                            <Center w="100%" h="100%"><Code colorScheme='telegram'> 
                            {"String[] topicos = {\"Topico1\", \"Topico2\"};"}<br/>
                            {"MQTT.Suscribe(topicos, new MQTTListener() {"}<br/>
                            {"@Override"}<br/>
                            {"public void onSuccess() {"}<br/>
                            {"// Operación exitosa"}<br/>
                            {"}"}<br/>
                            {"@Override"}<br/>
                            {"public void onFailure() {"}<br/>
                            {"// Fallo en la operación"}<br/>
                            {"}"}<br/>
                            {"});"}
                            </Code></Center>
                        </Box>
                    </Stack>
                </Box>







                <Box className="seccion">
                    <Box className="subTitulo" id="cancelarSuscripcion"><p>Cancelar suscripción</p></Box>
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","50%","50%"]}>
                            <Box className="textoContenido"><p>Para cancelar una suscripción a un tópico utiliza el siguiente método.</p></Box>
                        </Box>
                        <Box  w={["100%","100%","50%","50%"]} h="auto">
                            <Center w="100%" h="100%">
                                <Code colorScheme='telegram'>
                                {"MQTT.UnSuscribe(\"Topico\", new MQTTListener() {"}<br/>
                                {"@Override"}<br/>
                                {"public void onSuccess() {"}<br/>
                                {"// Operación exitosa"}<br/>
                                {"}"}<br/>
                                {"@Override"}<br/>
                                {"public void onFailure() {"}<br/>
                                {"// Fallo en la operación"}<br/>
                                {"}"}<br/>
                                {"});"}
                                </Code></Center>
                        </Box>
                    </Stack>
                </Box>





                <Box className="seccion">
                    <Box className="subTitulo" id="callback"><p>Devolución de llamada (Callback)</p></Box>
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","40%","40%"]}>
                        <Box className="texto"><p>Callback</p></Box>
                            <Box className="textoContenido"><p>Cuando estés conectado y suscrito a uno o varios tópicos puedes recibir mensajes de los tópicos implementando el siguiente método.
                                                            Disconect(listener)</p></Box>
                        </Box>
                        <Box  w={["100%","100%","60%","60%"]} h="auto">
                            <Center w="100%" h="100%">
                                <Code colorScheme='telegram'>
                                {"MQTT.Callback(new MQTTCallbackListener() {"}<br/>
                                {"@Override"}<br/>
                                {"public void connectionLost(String cause) {"}<br/>
                                {"// Se perdió la conexión"}<br/>
                                {"}"}<br/>
                                {"@Override"}<br/>
                                {"public void messageArrived(String topic, String message) {"}<br/>
                                {"// Mensajes entrantes"}<br/>
                                {"}"}<br/>
                                {"});"}
                                </Code></Center>
                        </Box>
                    </Stack>
                </Box>






                <Box className="seccion">
                    <Box className="subTitulo" id="publicacion"><p>Publicación</p></Box>
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","40%","40%"]}>
                        <Box className="texto"><p>Publicar</p></Box>
                            <Box className="textoContenido"><p>Para enviar mensajes o publicar en algún tópico utiliza el siguiente método.</p></Box>
                        </Box>
                        <Box  w={["100%","100%","60%","60%"]} h="auto">
                            <Center w="100%" h="100%">
                                <Code colorScheme='telegram'>
                                {"MQTT.Publish(\"Topico\", \"Mensaje\", new MQTTListener() {"}<br/>
                                {"@Override"}<br/>
                                {"public void onSuccess() {"}<br/>
                                {"// Operación exitosa"}<br/>
                                {"}"}<br/>
                                {"@Override"}<br/>
                                {"public void onFailure() {"}<br/>
                                {"// Fallo en la operación"}<br/>
                                {"}"}<br/>
                                {"});"}
                                </Code></Center>
                        </Box>
                    </Stack>
                </Box>












                </Box>
            </Stack>
        </Box>
    );
}

export default AndroidMQTT;