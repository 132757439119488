import React from "react";
import "./UI.css"
import { Box, useColorModeValue, Stack,} from '@chakra-ui/react'
import { Code } from '@chakra-ui/react'
import { Center} from '@chakra-ui/react'
import {Link} from 'react-scroll'
import senoidalImg from "./senoidal.png"
import indicadorImg from "./appled.png"
import termometroImg from "./termometro.png"
import circularImg from "./circular.png"

function AndroidUI(){

    const colorBtn = useColorModeValue("#ff414d", "#ff414d");
    const sectionColorBG = useColorModeValue("#EFF5F6B8", "gray.800");

    return(
        <Box paddingTop={"10vh"}>
            <Box>
                <Box className="titulo"><p>Android UI</p></Box>
            </Box>
            <Stack direction={['column', 'column', 'row', 'row']} w={["100%","100%","100%","100%"]} h={["100%","100%","100%","100%"]}>
            <Stack className="menu" w={["100%","100%","20%","20%"]} h={["100%","100%","auto","auto"]}>
                <Stack position={["relative", "relative", "fixed", "fixed"]} direction={['row', 'row', 'column', 'column']}
                        h={["20%","20%","auto","auto"]} w={["100%","100%","20%","20%"]}>
                    <a>Secciones</a>
                    <Link className="Link" to="inicio" spy={true} smooth={true} > - Inicio</Link>
                    <Link className="Link" to="graficas" spy={true} smooth={true} > - Grafica</Link>
                    <Link className="Link" to="indicador" spy={true} smooth={true} > - Indicador LED</Link>
                    <Link className="Link" to="termometro" spy={true} smooth={true} > - Termometro</Link>
                    <Link className="Link" to="circular" spy={true} smooth={true} > - Indicador Circular</Link>
                </Stack>
            </Stack>
            <Box className="contenido">
                
                <Box className="subTitulo" id="inicio"><p>Para usar esta biblioteca</p></Box>
                <Box className="texto"><p>1.- Agregar Maven a Gradle Project</p></Box>
                    <Code colorScheme='telegram'>{"allprojects { "}
                            <span>&nbsp;</span> {"repositories {" } <br/>
                            <span>&nbsp;&nbsp;</span> {"..." }<br/>
                            <span>&nbsp;&nbsp;</span> {"maven { url 'https://jitpack.io'}"}<br/>
                            <span>&nbsp;</span> {"}"}<br/>
                        {"}"}
                    </Code>
                <Box className="texto">2.- Agrega la dependencia a Gradle APP</Box>
                    <Code colorScheme='telegram'>{"dependencies {"}<br/>
                            <span>&nbsp;</span>{"implementation 'androidx.legacy:legacy-support-v4:1.0.0'"}<br/>
                            <span>&nbsp;</span>{"implementation 'mx.electronica117:Android-MQTT:1.2'"}<br/>
                            {"}"}<br/>
                    </Code>                    
                
                <Box>
                    <p className="texto">Ultima versión:<span>&nbsp;&nbsp;</span> 
                    <a href="https://jitpack.io/#mx.electronica117/Android-UI">
                    <img src="https://jitpack.io/v/mx.electronica117/Android-UI.svg" alt=""/>
                    </a>
                    </p>
                </Box>  







                <Box className="seccion">
                    <Box className="subTitulo" id="graficas"><p>Graficas</p></Box>
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","60%","60%"]}>
                            <Box className="texto"><p>Graficar valores</p></Box>
                            <Box w={["100%","100%","100%","100%"]}>
                                <Box className="textoContenido"><p>Utiliza el método setValue(Float) para graficar punto por punto.</p></Box>
                                <Code colorScheme='telegram'> {"setValue(Float);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método setValue(List) para graficar un conjunto de valores.</p></Box>
                                <Code colorScheme='telegram'> {"setValue(List);"} </Code>
                            </Box>

                            <Box className="texto"><p>Definir valores máximos</p></Box>
                            <Box w={["100%","100%","100%","100%"]}>
                                <Box className="textoContenido"><p>Utiliza el método setMaxAmplitude(int) para establecer el valor máximo en Y.</p></Box>
                                <Code colorScheme='telegram'> {"setMaxAmplitude(int);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método setMaxX(int) para establecer el valor máximo en X.</p></Box>
                                <Code colorScheme='telegram'> {"setMaxX(int);"} </Code>
                            </Box>

                            <Box className="texto"><p>Posición del cero</p></Box>
                            <Box w={["100%","100%","100%","100%"]}>
                                <Box className="textoContenido"><p>Para establecer el cero a la mitad del canvas utiliza el método.</p></Box>
                                <Code colorScheme='telegram'> {"Center(true);"} </Code>
                                <Box className="textoContenido"><p>Para establecer el cero al inicio del canvas utiliza el método.</p></Box>
                                <Code colorScheme='telegram'> {"Center(false);"} </Code>
                            </Box>

                            <Box className="texto"><p>Configuración de la linea y el texto</p></Box>
                            <Box w={["100%","100%","100%","100%"]}>
                                <Box className="textoContenido"><p>Utiliza el método setLineColor(int color) para establecer un color a la linea.</p></Box>
                                <Code colorScheme='telegram'> {"setLineColor(Color.WHITE);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método setLineSize(int color) para establecer un ancho a la linea, el valor por defecto es 5.</p></Box>
                                <Code colorScheme='telegram'> {"setLineSize(int);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método setText(String) para establecer un texto junto al valor.</p></Box>
                                <Code colorScheme='telegram'> {"setText(\"Unidades\");"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método staticText(boolean) para visualizar el valor y texto en la esquina superior izquierda.</p></Box>
                                <Code colorScheme='telegram'> {"staticText(true);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método valueEnabled(boolean) para visualizar el valor junto a la linea.</p></Box>
                                <Code colorScheme='telegram'> {"valueEnabled(true);"} </Code>
                            </Box>
                        </Box>
                        <Box  w={["100%","100%","40%","40%"]} h="auto">
                            <Center height="100%"><img src={senoidalImg}></img></Center>
                        </Box>
                    </Stack>
                </Box>







                <Box className="seccion">
                    <Box className="subTitulo" id="indicador"><p>Indicador LED</p></Box>
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","60%","60%"]}>
                            <Box className="texto"><p>Valores RGB</p></Box>
                            <Box w={["100%","100%","100%","100%"]}>
                                <Box className="textoContenido"><p>Utiliza el método setR(int) para modificar la intensidad del color rojo. Admite valores de 0 a 255.</p></Box>
                                <Code colorScheme='telegram'> {"setR(255);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método setG(int) para modificar la intensidad del color verde. Admite valores de 0 a 255.</p></Box>
                                <Code colorScheme='telegram'> {"setG(255);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método setB(int) para modificar la intensidad del color azul. Admite valores de 0 a 255.</p></Box>
                                <Code colorScheme='telegram'> {"setB(255);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método setRGB(int, int, int) para modificar la intensidad del los tres colores a la vez. Admite valores de 0 a 255.</p></Box>
                                <Code colorScheme='telegram'> {"setRGB(55, 220, 110);"} </Code>
                            </Box>

                        </Box>
                        <Box  w={["100%","100%","40%","40%"]} h="auto">
                            <Center height="100%"><img src={indicadorImg}></img></Center>
                        </Box>
                    </Stack>
                </Box>






                <Box className="seccion">
                    <Box className="subTitulo" id="termometro"><p>Termometro</p></Box>
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","60%","60%"]}>
                            <Box w={["100%","100%","100%","100%"]}>
                                <Box className="textoContenido"><p>Utiliza el método setMax(int) para establecer el valor máximo del termómetro.</p></Box>
                                <Code colorScheme='telegram'> {"setMax(200);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método setTemperature(int) para establecer el nivel de temperatura.</p></Box>
                                <Code colorScheme='telegram'> {"setTemperature(117);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método gradientEnabled(bool) para activar el gradiente.</p></Box>
                                <Code colorScheme='telegram'> {"gradientEnabled(true);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método setColorGradient_1(int) para establecer el primer color.</p></Box>
                                <Code colorScheme='telegram'> {"setColorGradient_1(Color.Blue);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método setColorGradient_2(int) para establecer el segundo color.</p></Box>
                                <Code colorScheme='telegram'> {"setColorGradient_1(Color.Red);"} </Code>
                            </Box>

                        </Box>
                        <Box  w={["100%","100%","40%","40%"]} h="auto">
                            <Center height="100%"><img src={termometroImg}></img></Center>
                        </Box>
                    </Stack>
                </Box>




                <Box className="seccion">
                    <Box className="subTitulo" id="circular"><p>Indicador circular</p></Box>
                    <Stack width="100%" direction={['column', 'column', 'row', 'row']}>
                        <Box w={["100%","100%","60%","60%"]}>
                            <Box w={["100%","100%","100%","100%"]}>
                                <Box className="textoContenido"><p>Utiliza el método setMax(int) para establecer el valor máximo del indicador.</p></Box>
                                <Code colorScheme='telegram'> {"setMax(200);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método setTemperature(int) para establecer el nivel del indicador.</p></Box>
                                <Code colorScheme='telegram'> {"setValue(117);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método setColor(int) para cambiar de color el indicador.</p></Box>
                                <Code colorScheme='telegram'> {"setColor(Color.RED);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método gradientEnabled(bool) para activar el gradiente.</p></Box>
                                <Code colorScheme='telegram'> {"gradientEnabled(true);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método setColorGradient_1(int) para establecer el primer color.</p></Box>
                                <Code colorScheme='telegram'> {"setColorGradient_1(Color.Blue);"} </Code>
                                <Box className="textoContenido"><p>Utiliza el método setColorGradient_2(int) para establecer el segundo color.</p></Box>
                                <Code colorScheme='telegram'> {"setColorGradient_1(Color.Red);"} </Code>
                            </Box>

                        </Box>
                        <Box  w={["100%","100%","40%","40%"]} h="auto">
                            <Center height="100%"><img src={circularImg}></img></Center>
                        </Box>
                    </Stack>
                </Box>




                </Box>
            </Stack>
        </Box>
    );
}

export default AndroidUI;