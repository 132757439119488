import React from "react";
import {useEffect, useState } from "react";
import { Divider } from '@chakra-ui/react'
import {
  chakra,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Box,
  Flex,
  IconButton,
  useDisclosure,
  CloseButton,
  Button,
  SimpleGrid,
  Stack,
  Spacer,
  Heading,
  PopoverBody,
  PopoverArrow,
} from "@chakra-ui/react";
import NavLink from "../nav-link";
import { useViewportScroll } from "framer-motion";
import { Image } from '@chakra-ui/react'
import { AiFillHome, AiOutlineMenu } from "react-icons/ai";
import './style.css'
import {FaGraduationCap, FaRegImage, FaFacebook, FaInstagram, FaDiscord } from "react-icons/fa";
import logo from "./logo.ico"
import { ArrowUpDownIcon } from "@chakra-ui/icons";
import { MoonIcon, SunIcon } from '@chakra-ui/icons';


export default function Header(props) {
  const [y, setY] = React.useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    // Creamos una función para actualizar el estado con el clientWidth
    const updateWidth = () => {
      const width = document.body.clientWidth
      //console.log(`updateWidth con ${width}`)
      setWidth(width)
    }
    // Actualizaremos el width al montar el componente
    updateWidth()
    // Nos suscribimos al evento resize() de window
    window.addEventListener("resize", updateWidth)
  })

  const { scrollY } = useViewportScroll();
  React.useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()));
  }, [scrollY]);
  const mobileNav = useDisclosure();

  const Section = (props) => {

    return (
      <Link 
        zIndex="1"
        display="flex"
        alignItems="flex-start"
      >
        <chakra.svg
          zIndex="1"
          h={6}
          w={6}
        >
          {props.icon}
        </chakra.svg>
        <Box ml={4} zIndex="1">
          <chakra.p fontSize="sm" fontWeight="700" color={"gray.50"}>
            {props.title}
          </chakra.p>
          <chakra.p mt={1} fontSize="sm" color={"gray.50"}>
            {props.children}
          </chakra.p>
        </Box>
      </Link>
    );
  };  

  
  return (
    <>
    <React.Fragment>
      <Box
      zIndex={2}
      
        display="flex"
        flexDirection={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        padding="5px 10px"
        width="full"  
        height={"10%"} 
        position={"fixed"}   
        background="gray.400"
      >
        
            <Link href="/">
              <Flex  align="center" direction="row">
                  <Image margin='10px 0px' boxSize='50px' src={logo} alt='Electronica117'  />
                  <Heading padding='5px 5px' size="lg" color="black" mt={0.2} ml={1}  >
                    <span className="tipografiaLight">Electronica117</span>
                  </Heading>
              </Flex>
            </Link>
            
            <Spacer />
                        
            <NavLink to="/Cursos">
              <Section
                icon={<FaGraduationCap size="25px"/>}
                >
                  <Box color="black" className="tipografia" fontSize="lg">
                    Cursos
                  </Box>
              </Section>
            </NavLink>

            {/*  
            <Popover >
              {({ isOpen, onClose }) => (
                <>      
                        <PopoverTrigger >
                            <Button
                            display={{ md: "flex", base: "none" }}
                            color="white" 
                            marginLeft={2}
                            bg={"#575757"} 
                            fontFamily="Round"
                            margin={"5px"}
                            _hover={{ bg: "white", color: "black"}}
                            rightIcon={< ArrowUpDownIcon/>}
                            >
                              Más
                            </Button>
                        </PopoverTrigger>
                          <PopoverContent
                            borderRadius="17px"
                            w="100vw"
                            maxW="md"
                            _focus={{ boxShadow: "md" }}
                            bg={"gray.800"}
                          >
                              <PopoverBody>
                              
                              <Box width={"full"} display="flex" flexDirection={"column"} alignItems="flex-start" justifyContent="center">
                                  <SimpleGrid
                                    columns={props.h ? { base: 1, md: 3, lg: 5 } : 1}
                                    pos="relative"
                                    className="tipografiaLight"
                                    gap={{ base: 6, sm: 8 }}
                                    px={5}
                                    py={6}
                                    p={{ sm: 8 }}
                                  >
                                    <NavLink to="/Cursos" onClick={onClose}>
                                      <Section
                                        title="Cursos"
                                        icon={
                                          <FaGraduationCap size="25px"/>
                                        }
                                        >
                                        Continua aprendiendo !!!
                                      </Section>
                                    </NavLink>
                          

                                  </SimpleGrid>

                                  <Divider />

                                  <Box width={"full"}
                                  margin="10px 0px"
                                   justifyContent="space-around" display={"flex"} flexDirection={"row"} >
                                            <Link
                                                href="https://discord.gg/funkf43cT8"
                                                isExternal
                                                onClick={onClose}
                                                p={3}
                                                display="flex"
                                                alignItems="center"
                                                rounded="md"
                                                fontSize="md"
                                            >
                                                <chakra.svg
                                                flexShrink={0}
                                                h={6}
                                                w={6}
                                                color="gray.400"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                                >
                                                <FaDiscord size="25px"/>
                                                </chakra.svg>
                                            </Link>
                                            
                                            <Link
                                                href="https://www.facebook.com/Electr%C3%B3nica117-104901341227486"
                                                isExternal
                                                onClick={onClose}
                                                p={3}
                                                display="flex"
                                                alignItems="center"
                                                rounded="md"
                                                fontSize="md"
                                            >
                                                <chakra.svg
                                                flexShrink={0}
                                                h={6}
                                                w={6}
                                                color="gray.400"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                                >
                                                <FaFacebook size="25px"/>
                                                </chakra.svg>
                                            </Link>
                                            <Link
                                                href="https://www.instagram.com/electronica117.mx/"
                                                isExternal
                                                onClick={onClose}
                                                p={3}
                                                display="flex"
                                                alignItems="center"
                                                rounded="md"
                                                fontSize="md"
                                            >
                                                <chakra.svg
                                                flexShrink={0}
                                                h={6}
                                                w={6}
                                                color="gray.400"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                                >
                                                <FaInstagram size="25px"/>
                                                </chakra.svg>
                                            </Link>
                                            
                                          
                                    
                                  </Box>
                                </Box>

                              </PopoverBody>
                          </PopoverContent>
                        </>
              )}
            </Popover>
           */}
                
             

      </Box>


    </React.Fragment>

   
    </>
  );
}