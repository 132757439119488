import MotorAPasos from './assets/img/28byj-48-min.png'
import Usb from './assets/img/cableUSB-min.png'
import DHT11 from './assets/img/DHT11-min.png'
import DriverMotorAPasos from './assets/img/driverMotorAPasos-min.png'
import ESP32 from './assets/img/ESP32-min.png'
import Jumper from './assets/img/Jumper.png'
import L293D from './assets/img/L293D-min.png'
import LCD from './assets/img/LCD-min.png'
import LCDI2C from './assets/img/LCDi2C-min.png'
import MotorCD from './assets/img/motorCD-min.png'
import OLED from './assets/img/OLED-min.png'
import PIR from './assets/img/PIR-min.png'
import Potenciometro from './assets/img/potenciometro-de-1k-min.png'
import Protoboard from './assets/img/Protoboard.png'
import PuenteH from './assets/img/puenteH-min.png'
import BotonPush from './assets/img/Push-min.png'
import Rele from './assets/img/rele-min.png'
import LED from './assets/img/rgbled-1-min.png'
import ServoMotor from './assets/img/servo-min.png'
import Ultrasonico from './assets/img/Ultrasonico-min.png'

const materiales = [
    {
        name: "Protoboard",
        description: "Placa de pruebas para prototipar circuitos electrónicos sin soldar componentes.",
        img: Protoboard,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "ESP32",
        description: "Pequeño dispositivo para conectar proyectos a internet de forma inalámbrica, ideal para proyectos de IoT.",
        img: ESP32,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "USB",
        description: "Cable tipo micro USB.",
        img: Usb,
        textoEnlace: "Comprarlo (ESP32 + USB)",
        enlace: ''
    },
    {
        name: "Jumpers",
        description: "Cables cortos para hacer conexiones temporales entre componentes.",
        img: Jumper,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "Push boton",
        description: "Interruptor utilizado para encender o apagar dispositivos electrónicos con un simple toque.",
        img: BotonPush,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "Potenciómetro",
        description: "Resistencia variable, el valor del potenciómetro puede ser de 10kΩ ~ 100kΩ",
        img: Potenciometro,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "LED RGB",
        description: "Un diodo LED que cambia entre tres colores.",
        img: LED,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "LCD 16x2 con adaptador I2C",
        description: "Pantalla utilizada para mostrar texto.",
        img: LCD,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "OLED 128x64",
        description: "Presenta información de manera clara y nítida, utilizando tecnología de diodos orgánicos emisores de luz.",
        img: OLED,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "Relevador",
        description: "Un interruptor robusto que maneja dispositivos de alta potencia con señales de bajo voltaje.",
        img: Rele,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "Motor de CD",
        description: "Un dispositivo que convierte la energía eléctrica en movimiento mecánico.",
        img: MotorCD,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "Puente H, L298N",
        description: "Un componente que controla la dirección y velocidad de los motores eléctricos.",
        img: PuenteH,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "Puente H, integrado L293D (Opcional)",
        description: "Un componente que controla la dirección y velocidad de los motores eléctricos.",
        img: L293D,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "Motor a pasos, 28byj-48",
        description: "Un motor que mueve dispositivos con precisión y control.",
        img: MotorAPasos,
        textoEnlace: "Comprarlo (Motor + Driver)",
        enlace: ''
    },
    {
        name: "Modulo ULN2003",
        description: "Un circuito integrado que facilita el control de motores a pasos de manera eficiente.",
        img: DriverMotorAPasos,
        textoEnlace: "Comprarlo (Motor + Driver)",
        enlace: ''
    },
    {
        name: "Servo motor",
        description: "Motor que puede posicionarse con precisión en ángulos específicos.",
        img: ServoMotor,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "DHT11",
        description: "Sensor que mide la temperatura y humedad del entorno de manera precisa.",
        img: DHT11,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "Sensor PIR",
        description: "Sensor que detecta movimiento en su área de detección.",
        img: PIR,
        textoEnlace: "Comprarlo",
        enlace: ''
    },
    {
        name: "Sensor ultrasonico, HC-SR04",
        description: "Sensor que utiliza ultrasonidos para medir distancias entre objetos.",
        img: Ultrasonico,
        textoEnlace: "Comprarlo",
        enlace: ''
    }
];


export default materiales;