import React from "react";
import { chakra, Box, Image, useColorModeValue, Link} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import './cardProductos.css'

function Ma(props) {
  return (
      <Box
        className="cardEffect"
        margin="25px 0px"
        maxW="xs"
        mx="auto"
        w={60}
        bg={useColorModeValue("#fafafa", "gray.600")}
        shadow="xl"
        rounded="lg"
        overflow="hidden"
      >

        <Image
          h={48}
          w="full"
          fit="cover"
          src={props.img}
          overflow="hidden"
        />
        <Box px={4} py={2}>
          <chakra.h1
            color={useColorModeValue("gray.800", 'white')}
            fontWeight="bold"
            fontSize="xl"
            textTransform="uppercase"
            fontFamily="Round"
          >
            {props.name}
          </chakra.h1>
          <chakra.p
            mt={1}
            fontSize="sm"
            color={useColorModeValue("gray.600", 'gray.300')}
            fontFamily="Round"
            textAlign="justify"
          >
            {props.description}
          </chakra.p>
        
          <Box mt={4}>
          <Link href={props.enlace} isExternal>
            {props.textoEnlace} <ExternalLinkIcon fontSize="lg" />
          </Link>
          </Box>
        </Box>
      </Box>
    
  );
};

export default Ma;