

const Contacto = () => {
 
  return (
   
      <p>Contacto</p>
    
  );
};

export default Contacto;