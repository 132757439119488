const Secciones = [
    {titulo: "Sección 1 - Introducción", 
    temas: [
        "- Introducción al curso",
        "- Entorno de trabajo",
        "- Hola... ESP32",
        "- Descargar proyecto de la plataforma",
        "- Agregar bibliotecas",
    ]},
    {titulo: "Sección 2 - Lo básico", 
    temas: [
        "- Introducción a la comunicación serial",
        "- Tipos de variables",
        "- Operador modulo, incrementos y decrementos",
        "- Operadores lógicos y relacionales",
        "- Sentencia IF y el operador (?)",
        "- Selector",
        "- Ciclos For, While, Do-While",
        "- Arreglos",
        "- Funciones",
    ]},
    {titulo: "Sección 3 - El ESP32", 
    temas: [
        "- Entrada y Salidas Digitales",
        "- Entrada Analógica",
        "- Salida Analógica",
        "- Interrupciones externas",
        "- Timer",
        "- PWM",
    ]},
    {titulo: "Sección 4 - Comunicación", 
    temas: [
        "- Comunicación serial",
        "- Serial bluetooth",
        "- WiFi (STA)",
        "- Serialización (JSON)",
        "- Deserialización (JSON)",
    ]},
    {titulo: "Sección 5 - Servidor Web", 
    temas: [
        "- Servidor Web",
        "- Servidor Web II - Enviando informacion del servidor al ESP32",
        "- Servidor Web III - Enviando informacion del ESP32 al servidor",
        "- Busqueda de redes WiFi con el ESP32",
        "- Preferences - Almacenamiento en memoria flash",
        "- Portal cautivo",
        "- Portal cautivo II",
        "- Portal cautivo III - Biblioteca Electrónica117",
    ]},
    {titulo: "Sección 6 - Firebase", 
    temas: [
        "- Crear base de datos Firebase",
        "- Escritura en la base de datos firebase",
        "- Lectura en la base de datos firebase",
        "- JSON en firebase (Serialización)",
        "- JSON en firebase (Deserialización)",
        "- Función callback",
        "- Practica Firebase",
    ]},
    {titulo: "Sección 7 - MQTT", 
    temas: [
        "- Protocolo MQTT",
        "- Servidor MQTT publico",
        "- MQTT - Publicación",
        "- MQTT - Suscripción I",
        "- MQTT - Suscripción II",
        "- JSON en MQTT (Serialización)",
        "- JSON en MQTT (Deserialización)",
        "- Control de led RGB",
    ]},
    {titulo: "Sección 8 - Displays", 
    temas: [
        "- LCD 16x2",
        "- OLED 128x64",
    ]},
    {titulo: "Sección 9 - Bobinas", 
    temas: [
        "- Relevador",
        "- Control de un motor de CD I",
        "- Control de un motor de CD II",
        "- Control de un motor de CD II",
        "- Control de un servo motor",
        "- Control de un motor a pasos I",
        "- Control de un motor a pasos II",
    ]},
    {titulo: "Sección 10 - Modulos", 
    temas: [
        "- DHT11",
        "- Sensor PIR (Sensor de movimiento)",
        "- HC-SR04 (Sensor ultrasonico)",
    ]},
    {titulo: "Sección 11 - Android (java)", 
    temas: [
        "- Introducción",
        "- Hola... Android",
        "- Lo básico de Android I",
        "- Lo básico de Android II",
        "- Creando nuestros objetos",
        "- JSON en Android",
        "- MQTT Android",
        "- Practica final",
    ]},
    
];

export default Secciones;