import React from "react";
import './curso_esp32_iot.css'
import { Heading } from '@chakra-ui/react'
import { Image, Box, Text, Link, useColorModeValue, Button, Stack, Divider, Grid} from '@chakra-ui/react'
import portada from '../img/portada-curso-esp32.png'
import CardProducto from '../../../layouts/cards/productos'
import Materiales from './materiales.js'
import Secciones from './secciones.js'
import CardPresentacion from '../../../layouts/cards/presentacion'
import Cupones from "../../../Cupones";


function CursoESP32(){

    const colorBtn = useColorModeValue("#ff414d", "#ff414d");
    const sectionColorBG = useColorModeValue("#EFF5F6B8", "gray.800");

    return(
        <Box paddingTop={"10vh"}>

            <Heading size='4xl' align="center" fontFamily="Round">Crea tu Dispositivo IoT!!!</Heading >
            <Box w="100%" align="center">
                <Box w="85%" p={4} display={{ md: 'flex' }} alignItems="center">
                    <Box flexShrink={0} shadow="lg">
                        <Image
                            borderRadius='lg'
                            width={{ md: 80 }}
                            src={portada}
                            alt='ESP32 Curso'
                            shadow="lg"
                        />
                    </Box>
                    <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
                        <Text
                            fontFamily="Round"
                            mt={1}
                            display='block'
                            fontSize='3xl'
                            lineHeight='normal'
                            fontWeight='semibold'
                        >
                            ¡Sumérgete en el emocionante mundo del Internet de las cosas (IoT)!
                        </Text>
                        <br />
                        <Text
                            fontFamily="Round"
                            mt={1}
                            display='block'
                            fontSize='2xl'
                            lineHeight='normal'
                            fontWeight='semibold'
                        >
                            ¡¿Te gustaría construir tu propio dispositivo IoT y dar vida a tus ideas más innovadoras?
                        </Text>
                        <br />
                        <Text fontSize='lg' fontFamily="Round" mt={2} color='gray.500' textAlign="justify">
                            ¡Imagina tener el control total de tus dispositivos 🌍 desde cualquier rincón del mundo! Al final del curso, no solo comprenderás IoT, sino que también habrás creado tu propia aplicación 📱 para controlar tus dispositivos desde la palma de tu mano.
                        </Text>
                        <br />
                        <Text fontSize='lg' fontFamily="Round" color='gray.500'>
                            ¡No pierdas la oportunidad de convertirte en un experto en IoT! Únete a nosotros y descubre un mundo lleno de posibilidades ilimitadas. 🚀✨
                        </Text>
                    </Box>
                </Box>
            </Box>

            





            
            <br /><br />
            <Text margin="10px 10px" fontSize='2xl' fontFamily="Round" mt={2} align="center">
                ¡Nuestro curso es completamente en línea, así que comienza cuando prefieras y avanza a tu propio ritmo!
            </Text>
            <br />
            <Text margin="10px 10px" fontSize='2xl' fontFamily="Round" mt={2} align="center">
                Cada lección cuenta con programas de ejemplo que puedes descargar.
            </Text>
            <br />
            <Text margin="10px 10px" fontSize='2xl' fontFamily="Round" mt={2} align="center">
                Puedes acceder al curso en la plataforma de Udemy.
            </Text>
            <br />
            <Text margin="5px 5px" fontSize='5xl' fontFamily="Round" mt={2} align="center">
                Obten un descuento !!!
            </Text>


            <Stack direction='column' align="center">
                <Link href={Cupones.CursoIoT} isExternal bg={colorBtn} _hover={{bg:colorBtn}} w={[300, 400, 500]} h="50px" borderRadius="full" align="center" className="myButton">
                <Button fontFamily="Round" bg={colorBtn} _hover={{bg:colorBtn}} w="100%" h="100%" borderRadius="full" variant="ghost"
                        fontSize='4xl' align="center" color="white">
                    Lo quiero!!!
                </Button>
                </Link>
            </Stack>


            <Box w="100%" align="center"  margin="30px 0px">
                <Divider w="90%" borderWidth="2px" orientation='horizontal' color="white"/>
            </Box>


            

            <Box w="100%" bg={sectionColorBG} padding="25px 0px">
                <Heading margin="10px 0px" size='4xl' align="center" fontFamily="Round">
                    Requisitos
                </Heading >

                <Stack w="100%" align="center">
                    <Text w="85%" fontSize='xl' fontFamily="Round" mt={2} color='gray.500'>
                        - Tener una cuenta de gmail !!!
                    </Text>
                    <br />
                    <Text w="85%" fontSize='xl' fontFamily="Round" mt={2} color='gray.500'>
                        - Tener Visual Studio Code instalado en tu PC, si no tienes instalado Visual aquí abajo podrás encontrar el enlace para que lo descargues y lo instales.
                        *Si ya tienes Visual Studio Code es hora de instalar platformIO, si no lo tienes te dejo una guía de como instalarlo.
                    </Text>
                    <br />
                    <Text w="85%" fontSize='xl' fontFamily="Round" mt={2} color='gray.500'>
                        - Tener el material mínimo descrito mas adelante.
                    </Text>
                    <br />
                    <Text w="85%"  fontSize='xl' fontFamily="Round" mt={2} color='gray.500'>
                        - Tener Android Studio instalado en tu PC, si aun no lo tienes instalado te dejo el enlace para que lo descargues y lo instales.
                    </Text>
                </Stack>

                <Box w="100%" align="center">
                    <Stack spacing={50} direction={['column', 'column', 'row', 'row']} margin="25px 0px" w="85%" align="center">
                        <Link href="https://code.visualstudio.com/download" isExternal color="white" fontSize='4xl' fontFamily="Round" bg={colorBtn} _hover={{bg:colorBtn}} w={[300, 400, 500]} h="fit-content" borderRadius="full" align="center" className="myButton">
                            VS Code
                        </Link>
                        <Link href={"#/Curso-ESP32-IoT/installplatformio"} color="white" fontSize='4xl' fontFamily="Round" bg={colorBtn} _hover={{bg:colorBtn}} w={[300, 400, 500]} h="fit-content" borderRadius="full" align="center" className="myButton">
                            PlatformIO
                        </Link>
                        <Link href="https://developer.android.com/studio?hl=es-419&gclid=CjwKCAjwieuGBhAsEiwA1Ly_ne_yO0CRDdECbnNnKlY-3cA_iWGDAO7D0--Vns4l7QlwTLHw4usTGhoCeewQAvD_BwE&gclsrc=aw.ds" isExternal color="white" fontSize='4xl' fontFamily="Round" bg={colorBtn} _hover={{bg:colorBtn}} w={[300, 400, 500]} h="fit-content" borderRadius="full" align="center" className="myButton">
                            Android Studio
                        </Link>
                    </Stack>
                </Box>
                
            </Box>





            <Box w="100%" align="center"  margin="30px 0px">
                <Divider w="90%" borderWidth="2px" orientation='horizontal' color="white"/>
            </Box>




            
            <Box w="100%"padding="25px 0px" align="center">
                <Heading margin="10px 0px" size='4xl' align="center" fontFamily="Round">
                    Material
                </Heading >

                <Grid templateColumns={['repeat(1, 2fr)', 'repeat(1, 2fr)', 'repeat(2, 2fr)', 'repeat(4, 2fr)']} 
                            gap={[1, 10, 10, 5]} w="85%">

                    {Materiales.map(material => (
                        <CardProducto name={material.name} description={material.description} img={material.img}
                                        enlace={material.enlace} textoEnlace={material.textoEnlace}/>    
                            )
                        )}

                </Grid>

            </Box>


           

            <Box w="100%" align="center"  margin="30px 0px">
                <Divider w="90%" borderWidth="2px" orientation='horizontal' color="white"/>
            </Box>
            




            <Box w="100%" bg={sectionColorBG} padding="25px 0px" align="center">
                <Heading margin="10px 0px" size='4xl' align="center" fontFamily="Round">
                    Temario
                </Heading >
                <br /><br /><br />


                <Grid fontFamily="Round" align="left" templateColumns={['repeat(1, 2fr)', 'repeat(1, 2fr)', 'repeat(2, 2fr)', 'repeat(2, 2fr)']} 
                            gap={7} w="85%">

                    {Secciones.map(Seccion => (
                        <Box>
                            <Heading margin="15px 0px" fontSize={['2xl', '2xl', '3xl', '3xl']}  fontFamily="Round">
                                {Seccion.titulo}
                            </Heading >
    
                            <Text >
                            {Seccion.temas.map(tema => (
                                <Text margin="2px 0px" fontSize={['xl', 'xl', '2xl', '2xl']}>{tema}</Text>
                            ))}
                            </Text>

                        </Box>
                  
                    ))}

                </Grid>

            </Box>




            <Box w="100%" align="center"  margin="30px 0px">
                <Divider w="90%" borderWidth="2px" orientation='horizontal' color="white"/>
            </Box>
            


            
            <Box w="100%" bg={sectionColorBG} padding="25px 0px" align="center">
                <Heading margin="10px 0px" size='4xl' align="center" fontFamily="Round">
                    Ponente
                </Heading >
                <CardPresentacion />

            </Box>



            <Box w="100%" align="center"  margin="30px 0px">
                <Divider w="90%" borderWidth="2px" orientation='horizontal' color="white"/>
            </Box>
            




            <Text margin="5px 5px" fontSize='5xl' fontFamily="Round" mt={2} align="center">
                Obten un descuento !!!
            </Text>

            <Box w="100%" padding="25px 0px" align="center">
                <Stack direction='column' align="center">
                    <Link href={Cupones.CursoIoT} isExternal bg={colorBtn} _hover={{bg:colorBtn}} w={[300, 400, 500]} h="50px" borderRadius="full" align="center" className="myButton">
                    <Button fontFamily="Round" bg={colorBtn} _hover={{bg:colorBtn}} w="100%" h="100%" borderRadius="full" variant="ghost"
                            fontSize='4xl' align="center" color="white">
                        Lo quiero!!!
                    </Button>
                    </Link>
                </Stack>
            </Box>

        </Box>     
    );
}

export default CursoESP32;