import React from "react";
import NavLink from "../../../layouts/nav-link";
import { chakra, Box, Flex, useColorModeValue} from "@chakra-ui/react";

function CardCursos(props){
  return (
    <Flex
      fontFamily="Round"
      bg={useColorModeValue("#F9FAFB", "gray.800")}
      p={5}
      w="full"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        bg={useColorModeValue("#F9FAFB", "gray.200")}
        w="85%"
        display={{ lg: "flex" }}
        maxW={{ lg: "5xl" }}
        shadow="lg"
        rounded="lg"
        overflow="hidden"
      >
        <Box w={{ lg: "30%" }}>
          <Box
            h={{ base: 60, lg: "full" }}
            rounded={{ lg: "lg" }}
            bgImg={props.img}
            bgSize="cover"
            bgRepeat="no-repeat"
            bgPosition="center"
          >
            
          </Box>
        </Box>

        <Box py={12} px={6} maxW={{ base: "xl", lg: "5xl" }} w={{ lg: "50%" }}>
          <chakra.h2
            fontSize={{ base: "2xl", md: "3xl" }}
            color={useColorModeValue("black", "black")}
            fontWeight="bold"
          >
            <chakra.span color={useColorModeValue("black", "black")}>
              {props.titulo}
            </chakra.span>
          </chakra.h2>
          <chakra.p mt={4} color={useColorModeValue("black", "black")}>
            {props.descripcion}
          </chakra.p>

          <Box mt={8}>
            <NavLink to={props.pagina}
              
              bg="gray.900"
              color="gray.100"
              px={5}
              py={3}
              fontWeight="semibold"
              rounded="lg"
              _hover={{ bg: "black" }}
            >
              Info
            </NavLink>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default CardCursos;