import {Routes, Route } from 'react-router-dom';
import Inicio from './views/inicio'
import TEST from './views/test'
import Cursos from './views/cursos'
import CursoESP32IoT from './views/cursos/curso_esp32_iot'
import Contacto from './views/contacto'
import Android_MQTT from './views/AndroidMQTT';
import AndroidUI from './views/AndroidUI';
import Platformio from './views/platformio';

function App(){ 

  
return ( 

    <Routes>

      <Route path="/" element={<Inicio />} />
      <Route path="/Cursos" element={<Cursos/>} /> 
      <Route path="/Curso-ESP32-IoT" element={<CursoESP32IoT/>} /> 
      <Route path="/Contacto" element={<Contacto/>} /> 
      <Route path="/Curso-ESP32-IoT/Android_MQTT" element={<Android_MQTT/>} /> 
      <Route path="/Curso-ESP32-IoT/Android_UI" element={<AndroidUI/>} /> 
      <Route path="/Curso-ESP32-IoT/InstallPlatformIO" element={<Platformio/>} /> 

      <Route path="/TestElec" element={<TEST />} />
      
    </Routes>
  
); 

} 

export default App 