import escritorioImg from './escritorio.png'
import './inicio.css'
import { Link, Text, Box, useColorModeValue, Button } from '@chakra-ui/react'

const Home = () => { 

    const colorBtn = useColorModeValue("#ff414d", "#ff414d");
    const enlace = "#/Cursos"; 

    return ( 
        <>
            
            <Box 
            paddingTop={"10vh"}
                h={["100vh", "100vh", "100vh", "100vh"]}
                w={["100wh", "100wh", "100wh", "100wh"]}>
                <Box paddingLeft={["0px", "0px", "30px", "30px"]} 
                        className='contenedorSobreImg'
                        backgroundImage={escritorioImg}
                        backgroundPosition={["75%","75%","center","center"]}
                        backgroundSize={["cover","cover","cover","cover"]}
                        bgRepeat="no-repeat"
                        >
                    <Text fontSize={["17vw","16vw","10vw","8vw"]} className='tituloInicio' textAlign={["center", "center", "left", "left"]}>Cursos</Text>
                    <Text fontSize={["17vw","16vw","10vw","8vw"]} className='tituloInicio' textAlign={["center", "center", "left", "left"]}>en linea</Text>
                    <Text fontSize={["7vw","6vw","4vw","3vw"]} className='tituloInicio' textAlign={["center", "center", "left", "left"]}    >Continua aprendiendo!!!</Text>

                    <Box width="100%" textAlign={["center", "center", "left", "left"]} paddingTop={["15px", "15px", "15px", "15px"]} >
                        <Link href={enlace} bg={colorBtn} _hover={{bg:colorBtn}} w={["50%", "50%", "40%", "40%"]} h={["auto", "auto", "auto", "auto"]} borderRadius="full" align="center" className="myButton">
                            <Button fontFamily="RoundLight" bg={colorBtn} _hover={{bg:colorBtn}} w={["50%", "50%", "40%", "40%"]} h="auto" borderRadius="full"
                                    fontSize={["6vw","6vw","4vw","3vw"]} align="center" color="white">
                                Cursos !!!
                            </Button>
                        </Link>
                    </Box>
                </Box>
           
            </Box>

        </>

    ); 

}; 

 

export default Home; 