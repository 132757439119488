import React from 'react'; 
import ReactDOM from 'react-dom'; 
import App from './App'; 
import { HashRouter } from 'react-router-dom'; 
import { ChakraProvider, Divider, Spacer } from "@chakra-ui/react" 
import Head from './layouts/header/header.js'
import Footer from './layouts/footer';
 

ReactDOM.render( 

  <React.StrictMode> 

      <HashRouter> 

        <ChakraProvider> 

          <Head />
          
          <App/>       

        </ChakraProvider> 

      </HashRouter> 

  </React.StrictMode>, 

  document.getElementById('root') 

); 