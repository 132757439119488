import React from "react";
import Cards from '../../layouts/cards/cursos'
import logo from '../cursos/img/portada-curso-esp32.png'
import { Box } from "@chakra-ui/react";

const cursos = [
    {titulo: "ESP32 IoT", 
    descripcion: "En este curso aprenderás a programar el ESP32, comienza a diseñar tu propio dispositivo IoT con el ESP32", 
    pagina:"/Curso-ESP32-IoT", 
    img: logo}
];

function Cursos(){
    return(
        <>
        <Box paddingTop={"10vh"}></Box>
        {cursos.map(curso => (
            <Box >
                    <Cards titulo={curso.titulo} descripcion={curso.descripcion} pagina={curso.pagina} img={curso.img}></Cards>
                </Box>
            )
        )}
        </>
    );
}

export default Cursos;