import React from "react";
import {
  chakra,
  Box,
  Image,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Text
} from "@chakra-ui/react";

import {FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";
import Foto from '../../../views/cursos/img/Edgar-1020x1024-min.jpg'

function Ma(props) {
  return (
    
    <Box 
  
    width={["80%", "60%", "30%", "25%"]}>
      <Box
      width={["90%", "90%", "80%", "80%"]}
      margin="50px 0px"
        mx="auto"
        bg={useColorModeValue("white", "gray.600")}
        shadow="lg"
        rounded="lg"
        overflow="hidden"
      >
        <Image
          w="full"
          h={56}
          fit="cover"
          objectPosition="center"
          src={Foto}
          alt="avatar"
        />

        <Box py={4} px={6}>
          <chakra.h1
            fontSize="xl"
            fontFamily={'Round'}
            fontWeight="bold"
            color={useColorModeValue("gray.800", "white")}
          >
            Edgar Domínguez 
          </chakra.h1>

          <chakra.p py={2} color={useColorModeValue("gray.700", "gray.400")}>
            Ingeniero en Comunicaciones y Electrónica
          </chakra.p>

          <chakra.p py={2} color={useColorModeValue("gray.700", "gray.400")}>
            Numero de cédula profecional 13205415
          </chakra.p>

          <Text
          fontSize="md"
          fontFamily={'Round'}
          color={useColorModeValue("gray.800", "white")}>
            {props.text}
          </Text>

          <Box display={'Flex'}  flexDirection={['row', 'row', 'row', 'row']} justifyContent="center">
          <Link href="https://www.facebook.com/EdgarDominguez117/" isExternal>
            <Flex
                alignItems="center"
                mt={4}
                color={useColorModeValue("gray.700", "gray.200")}
            >
                <Icon as={FaFacebook} h={6} w={6} mr={2} />

                <chakra.h1 px={2} fontSize="sm">
                
                </chakra.h1>
            </Flex>
        </Link>

        <Link href="https://www.instagram.com/ynot117/" isExternal>
          <Flex
            alignItems="center"
            mt={4}
            color={useColorModeValue("gray.700", "gray.200")}
          >
            <Icon as={FaInstagram} h={6} w={6} mr={2} />

            <chakra.h1 px={2} fontSize="sm">
             
            </chakra.h1>
          </Flex>
        </Link>

        <Link href="https://www.linkedin.com/in/edgar117/" isExternal>
            <Flex
              alignItems="center"
              mt={4}
              color={useColorModeValue("gray.700", "gray.200")}
            >
              <Icon as={FaLinkedin} h={6} w={6} mr={2} />

              <chakra.h1 px={2} fontSize="sm">
                
              </chakra.h1>
            </Flex>      
          </Link> 
          </Box>
        
        </Box>
      </Box>
    </Box>
  );
};

export default Ma;